import { memo, useState, useEffect, useContext } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import { withRouter } from 'react-router-dom'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import EasyTable from '@/components/EasyTable/EasyTable'
import FileLoader from '@/components/FileLoader/FileLoader'
import { getUserInfoApi } from '@/server/commonFetch'
import BasicSpin from '@/components/BasicSpin/BasicSpin'
import CommonFunc from '@/utils/common-func'
import { Modal, message, Button } from 'antd'
import './UserModal.less'
const unityUrl = process.env.VITE_NAME
const UserModal = (props: any) => {
  const uniToken = localStorage.getItem('uniToken')
  const {
    dispatch,
    userInfo,
    aiPaperData = [],
    lowChongData = [],
    lowAIGCData = [],
    openReportData = [],
    creatPPTData = []
  } = useContext<any>(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsModalOpen(props.visible)
    setLoading(true)
    getUserInfoApi({
      dispatch,
      CHAT_DEFAULT,
      onSuccess: () => {
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  const importFile = (data: any) => {
    console.log(data)
    localStorage.setItem('ai-paper-data', JSON.stringify(data.aiPaperData || []))
    localStorage.setItem('uniToken', data.uniToken)
    localStorage.setItem('ai-pager-userInfo', typeof data.userInfo === 'string' ? data.userInfo : JSON.stringify(data.userInfo))
    localStorage.setItem('low-chong-data', JSON.stringify(data.lowChongData || []))
    localStorage.setItem('low-aigc-data', JSON.stringify(data.lowAIGCData || []))
    localStorage.setItem('creat-ppt-data', JSON.stringify(data.creatPPTData || []))
    localStorage.setItem('open-report-data', JSON.stringify(data.openReportData || []))
    dispatch({
      type: CHAT_DEFAULT,
      payload: {
        aiPaperData: data.aiPaperData ? [...data.aiPaperData] : [],
        userInfo: typeof data.userInfo === 'string' ? JSON.parse(data.userInfo) : data.userInfo,
        uniToken: data.uniToken,
        lowChongData: data.lowChongData ? [...data.lowChongData] : [],
        lowAIGCData: data.lowAIGCData ? [...data.lowAIGCData] : [],
        creatPPTData: data.creatPPTData ? [...data.creatPPTData] : [],
        openReportData: data.openReportData ? [...data.openReportData] : []
      }
    })
    props.onCancel()
    if (data.aiPaperData && data.aiPaperData.length > 0) {
      props.history.push(unityUrl + '/mainbody')
      return
    }
    if (data.lowChongData && data.lowChongData.length > 0) {
      props.history.push(unityUrl + '/lowinfo/5')
      return
    }
    if (data.lowAIGCData && data.lowAIGCData.length > 0) {
      props.history.push(unityUrl + '/lowinfo/6')
      return
    }
    if (data.creatPPTData && data.creatPPTData.length > 0) {
      props.history.push(unityUrl + '/resulePPT')
      return
    }
    if (data.openReportData && data.openReportData.length > 0) {
      props.history.push(unityUrl + '/openReportbody')
      return
    }
  }
  const exportFile = () => {
    const file = JSON.stringify({ aiPaperData, uniToken, userInfo, lowChongData, lowAIGCData, openReportData, creatPPTData })
    CommonFunc.downloadTextAsFile(
      CommonFunc.encrypt(file),
      'AIUniPaper_存档_' + CommonFunc.timestampToTime(new Date().getTime(), true) + '.paper',
      () => {
        message.success('存档导出成功，请妥善保存')
      }
    )
    props.onCancel()
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} userModal-modal-container easy-modal`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      {loading && <BasicSpin theme='dark' />}
      <h4>当前用户</h4>
      <CopyToClipboard text={uniToken} onCopy={() => message.success('UniToken 已复制')}>
        <div className='token'>
          <span className='key'>UniToken：</span>
          <span className='value'>{uniToken}</span>
          <span className='copy'>（点击复制）</span>
        </div>
      </CopyToClipboard>
      <div className='title'>
        <div className='left'>
          <span className='key'>剩余字数：</span>
          <span className='value'>
            {userInfo.sum_words - userInfo.expend_words >= 0 ? userInfo.sum_words - userInfo.expend_words : 0}
          </span>
        </div>
        <div className='right'>
          <span className='key'>剩余PPT券：</span>
          <span className='value'>{userInfo.coupons.find((item: any) => item.type == 1)?.ppt_coupons_count || 0}</span>
        </div>
      </div>
      <EasyTable
        title='购买记录'
        className='buy-table'
        tbodyStyle={{ maxHeight: '.8rem' }}
        columns={[
          { title: '时间', dataIndex: 'created_at', key: 'created_at', style: { width: '46%' } },
          { title: '数量', dataIndex: 'words', key: 'words', style: { width: '22%' }, unit: '字' },
          { title: '购买描述', dataIndex: 'name', key: 'name', style: { width: '32%' } }
        ]}
        dataSource={userInfo.payment_records}
      />
      <EasyTable
        title='消耗记录'
        tbodyStyle={{ maxHeight: '1.2rem' }}
        columns={[
          { title: '时间', dataIndex: 'created_at', key: 'created_at', style: { width: '46%' } },
          { title: '数量', dataIndex: 'words', key: 'words', style: { width: '22%' }, unit: '字' },
          { title: '功能', dataIndex: 'function', key: 'function', style: { width: '32%' } }
        ]}
        dataSource={userInfo.expand_records}
      />
      <div className='btn-wrapper'>
        <FileLoader text='导入存档' callback={importFile} desc={false} />
        <Button type='primary' onClick={exportFile}>
          导出存档
        </Button>
      </div>
      <div className='tip'>注：存档为全部论文加密数据，建议每天及时备份，并妥善保存</div>
    </Modal>
  )
}

export default memo(withRouter(UserModal))
