import { memo, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { ThemeContext } from '@/store/store'
import BuyModal from '@/components/BuyModal/BuyModal'
import UserModal from '@/components/UserModal/UserModal'
import PlatformModal from '@/components/PlatformModal/PlatformModal'
import LunwenModal from '@/components/LunwenModal/LunwenModal'
import JoinGroup from '@/components/JoinGroup/JoinGroup'
// import { getUserInfoApi } from '@/server/commonFetch'
import { Progress, Button, Popover } from 'antd'
import './index.less'
const unityUrl = process.env.VITE_NAME
const dealData = [{ icon: 'icon-chongzhi' }, { icon: 'icon-icon-user' }, { icon: 'icon-icon-wenhao' }, { icon: 'icon-lunwen' }]
const Title = (props: any) => {
  const { userInfo = {} } = useContext<any>(ThemeContext)
  const [logo, setLogo] = useState('AI UniPaper')
  const [eyeIcon, setEyeIcon] = useState(false)
  const [buyVisible, setBuyVisible] = useState(false)
  const [userVisible, setUserVisible] = useState(false)
  const [platVisible, setPlatVisible] = useState(false)
  const [lunwenVisible, setLunwenrVisible] = useState(false)
  const [joinGroupVisible, setJoinGroupVisible] = useState(false)
  // useEffect(() => {
  //   // const localUserInfo = localStorage.getItem('ai-pager-userInfo')
  //   // if (!localUserInfo) {
  //   //   getUserInfo()
  //   // } else {
  //   //   dispatch({ type: CHAT_DEFAULT, payload: { userInfo: JSON.parse(localUserInfo) } })
  //   //   getUserInfo()
  //   // }
  //   getUserInfo()
  // }, [])
  // const getUserInfo = () => getUserInfoApi({ dispatch, CHAT_DEFAULT })

  const onMouseover = () => {
    setLogo('Home')
  }
  const onMouseout = () => {
    setLogo('AI UniPaper')
  }
  const iconClick = (index: number) => {
    if (index === 0) {
      setBuyVisible(true)
      return
    }
    if (index === 1) {
      setUserVisible(true)
      return
    }
    if (index === 2) {
      setPlatVisible(true)
      return
    }
    if (index === 3) {
      setLunwenrVisible(true)
      return
    }
  }
  return (
    <>
      <nav className='title-container'>
        <span
          className='logo'
          onMouseOver={onMouseover}
          onMouseOut={onMouseout}
          onClick={() => {
            props.history.push(unityUrl + '/')
          }}
        >
          {logo}
        </span>
        {eyeIcon ? null : (
          <>
            <Progress
              percent={
                userInfo.sum_words - userInfo.expend_words <= 0
                  ? 100
                  : Math.floor((userInfo.expend_words / userInfo.sum_words) * 100)
              }
              strokeColor='#000000'
            />
            <Popover placement='top' content={`已消耗字数${userInfo.expend_words} / 充值字数${userInfo.sum_words}`}>
              <span className='words'>
                <label>{userInfo.expend_words}</label> / <label>{userInfo.sum_words}</label>
              </span>
            </Popover>
            {userInfo.sum_words === 0 ? (
              <Button
                className='free-btn'
                type='primary'
                onClick={() => {
                  setJoinGroupVisible(true)
                }}
              >
                免费领取字数
              </Button>
            ) : null}
            <ul>
              {dealData.map((item: any, index: number) => {
                return (
                  <Popover key={index} placement='top' content={['充值中心', '用户信息', '使用说明', '论文管理'][index]}>
                    <li key={index}>
                      <i className={`icon iconfont ${item.icon}`} onClick={() => iconClick(index)} />
                    </li>
                  </Popover>
                )
              })}
            </ul>
          </>
        )}
        <Popover placement='top' content={'显示/隐藏'}>
          <i
            className={`icon iconfont dealicon ${eyeIcon ? 'icon-yanjing_xianshi_o' : 'icon-buxianshi1'}`}
            onClick={() => setEyeIcon(!eyeIcon)}
          />
        </Popover>
      </nav>
      {buyVisible && (
        <BuyModal
          visible={buyVisible}
          onCancel={() => {
            setBuyVisible(false)
          }}
        />
      )}
      {userVisible && (
        <UserModal
          visible={userVisible}
          onCancel={() => {
            setUserVisible(false)
          }}
        />
      )}
      {platVisible && (
        <PlatformModal
          visible={platVisible}
          onCancel={() => {
            setPlatVisible(false)
          }}
        />
      )}
      {lunwenVisible && (
        <LunwenModal
          visible={lunwenVisible}
          onCancel={() => {
            setLunwenrVisible(false)
          }}
        />
      )}
      {joinGroupVisible && (
        <JoinGroup
          visible={joinGroupVisible}
          onCancel={() => {
            setJoinGroupVisible(false)
          }}
        />
      )}
    </>
  )
}

export default memo(withRouter(Title))
