import { memo, useState, useEffect } from 'react'
import { Input, Button, message } from 'antd'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import BasicSpin from '@/components/BasicSpin/BasicSpin'
import CommonFunc from '@/utils/common-func'
const unityUrl = process.env.VITE_NAME

import './index.less'

const MobileActivity = () => {
  const param = CommonFunc.getUrlParams()
  const isWeixinBrowser = CommonFunc.isWeixinBrowser()
  const [spin, setSpin] = useState(false)
  const [input, setInput] = useState<any>(null)
  const [data, setData] = useState<any>({})
  const [open, setOpen] = useState<any>('')
  const [alreadyReceived, setAlreadyReceived] = useState<any>(false)
  useEffect(() => {
    if (!param.code) {
      if (isWeixinBrowser) {
        window.open(
          `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx5e9cf4df6e1a1f43&redirect_uri=https://ai.easylink.vip${unityUrl}/mobileActivity?type=2&response_type=code&scope=snsapi_userinfo&state=STATE`,
          '_self'
        )
      } else {
        setSpin(false)
        setOpen('请在微信中打开')
        message.info('请在微信中打开')
      }
      return
    } else {
      wechat_login()
      return
    }
  }, [])
  const wechat_login = async () => {
    try {
      // type:1. 扫码登录   2.微信公众号登录    3.app登录
      const res: any = await http('post', apiGroup.wechat_login, { code: param.code, type: 2 })
      if (res.status) {
        setData(res.data)
        localStorage.setItem('ai-paper-uid', res.data.unionid)
      } else {
        message.info(res.msg)
        location.href = unityUrl + '/mobileActivity'
      }
      setSpin(false)
    } catch {
      setSpin(false)
    }
  }
  const getCoupon = async () => {
    if (!input) {
      message.info('请输入UniToken，可在官网中进入任意功能领取')
      return
    }
    try {
      const res: any = await http('post', apiGroup.pay, { id: 1, count: 1, unitoken: input, unionid: data.unionid })
      if (res.status) {
        message.success('已领取')
        // localStorage.setItem('alreadyReceived', 'true')
        setAlreadyReceived(true)
      } else {
        message.info(res.msg)
      }
    } catch {
      message.info('请输入正确的UniToken')
      setSpin(false)
    }
  }
  return (
    <>
      {param.code ? (
        <div className='mobileActivity-container'>
          {!spin && open && <div className='wxopen'>{open}</div>}
          {spin ? (
            <BasicSpin theme='dark' />
          ) : (
            <>
              <h1>AI UniPaper</h1>
              <div className='desc1'>
                <span>专注大学论文</span>
                <span className='sp'>4.0</span>
                <span className='sp'>限时活动</span>
              </div>
              <div className='desc2'>AI辅助大学生撰写高质量论文</div>
              <div className='desc3'>
                {data.unionid ? (
                  <>
                    <span>当前用户：</span>
                    <span>{data.nickname}</span>
                  </>
                ) : (
                  <span>请在微信端打开</span>
                )}
              </div>
              <div className='desc4'>UniToken：</div>
              <Input
                placeholder='请输入UniToken（可在官网中进入任意功能获取）'
                value={input}
                onChange={e => {
                  setInput(e.target.value)
                }}
              />
              <p>领取须知：</p>
              <p>1、活动领取为5000字免费试用额度</p>
              <p>2、老用户可参与，活动额度将直接累加至可用字数</p>
              <p>3、每个UniToken、每位用户仅可领取一次</p>
              <p>4、请用户妥善保管UniToken，并在生成论文后及时导出存档，以防数据丢失</p>
              <p>5、平台不会对用户信息与UniToken进行关联，请放心参与</p>
              <p>6、该活动为限时活动，实际截止日期以官网发布为准</p>
              <p>7、最终解释权归属上海鉴联科技有限公司所有</p>
              <div className='btn-wrapper'>
                <Button type='primary' disabled={data.unionid && !alreadyReceived ? false : true} onClick={getCoupon}>
                  {data.unionid && !alreadyReceived ? '领取' : '已领取'}
                </Button>
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  )
}

export default memo(MobileActivity)
