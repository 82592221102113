/**
 *   create by zhanghang
 */

import { memo } from 'react'
import { Route, Switch } from 'react-router-dom'
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import asyncRouter from './router'
import { InterRouteConfig } from './router.d'
import Home from '@/layout/Home'
import Index from '@/pages/Index'
import MobileActivity from '@/pages/MobileActivity'
const unityUrl = process.env.VITE_NAME
function SubRoute(): JSX.Element | null {
  return (
    <Switch>
      <Route path={unityUrl + '/mobileActivity'} exact={true} component={MobileActivity} />
      <Route path={'/'} exact={true} component={Index} />
      <Home>
        <CacheSwitch>
          {asyncRouter.map((item: InterRouteConfig) => {
            return item.isUseCache ? (
              <CacheRoute
                className={item.class}
                path={item.path}
                exact={item.exact}
                key={item.name}
                cacheKey={item.code}
                component={item.components}
              />
            ) : (
              <Route path={item.path} exact={item.exact} key={item.name} component={item.components} />
            )
          })}
        </CacheSwitch>
      </Home>
    </Switch>
  )
}

export default memo(SubRoute)
