import { memo } from 'react'
import './BaseProgram.less'
const BaseProgram = (props: any) => {
  return (
    <div className='baseProgram-container' style={{ ...props.style }}>
      <div className='bar'>
        {props.data.map((item: any, index: number) => {
          return <div className={`bar-item bar-item${index}`} key={index} style={{ width: item.percent }}></div>
        })}
      </div>
      <div className='perent'>
        {props.data.map((item: any, index: number) => {
          return (
            <span className={`perent-item perent-item${index}`} key={index}>
              {item.name}：{item.percent}
            </span>
          )
        })}
      </div>
    </div>
  )
}

export default memo(BaseProgram)
