import { memo, useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import './PlatformModal.less'
const PlatformModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)

  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) {
      props.onOk()
    }
  }
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel()
    }
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} platformModal-modal-container easy-modal`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <h4>平台说明</h4>
      <div className='title'>【内容说明】</div>
      <p>
        1、本平台全部生成内容（选题、摘要、目录、正文）均采<span>用全球先进的 4.0 AI大模型。</span>
        全部生成内容均为AI原创，并非在各网络平台检索产生，<span>论文查重率会保持在10%左右。</span>
      </p>
      <p>2、请用户自行辨别内容中的观点、论点、内容的有效性，并保证政治倾向正确。</p>
      <p>
        3、建议每次编辑论文后<span>使用“导出存档”功能对最新论文数据进行备份，</span>并按存档的导出时间自行管理最新存档。
      </p>

      <div className='title'>【费用说明】</div>
      <p>平台功能以AI生成结果的字数计费（免费功能不消耗字数）</p>
      <p>
        <span>免费功能：生成标题、摘要、参考文献、目录大纲</span>
      </p>
      <p>
        <span>字数规则：1个汉字为1个字；1个英文单词为1个字</span>
      </p>

      <div className='title'>【隐私声明】</div>
      <p>
        1、平台承诺：<span>本平台高度重视论文数据安全及用户隐私安全，</span>
        平台绝不会记录任何用户个人信息；使用过程中所产生的全部论文数据仅保存在浏览器Cookie中，用户可随时通过清除浏览器缓存的方式删除所有数据。
      </p>
      <p>
        2、重要数据：<span>UniToken 令牌</span>是用户唯一的身份证明，包含用户的充值数据，<span>用户必须单独记录保存，</span>
        如若丢失或遗忘，本平台将不承担任何责任，且不存在找回功能。
      </p>
      <p>
        3、存档数据：存档数据包含论文目录、正文、参考文献等全部论文数据，采用加密形式存储，可在其他新设备上导入使用。
        <span>因存档文件包含全部论文数据，请用户必须妥善保管。</span>
      </p>
      {/* <h4 className='title'>平台说明</h4>
      <p>一、使用说明 </p>
      <p>
        1、本平台全部生成内容（选题、摘要、目录、正文）均采用先进的4.0
        AI大模型，全部为创作型内容，并非在各网络平台检索产生，所以重复率会保持在10%左右的较低水平。同时也请用户自行辨别内容中的观点、论点的有效性，并保证政治正确。
      </p>
      <p>2、建议每天使用导出存档功能对最新论文数据进行备份，并按存档的导出时间自行管理最新存档。</p>
      <p style={{ marginTop: '.2rem' }}>二、隐私声明</p>
      <p>
        1、平台承诺：本平台严密关注论文隐私安全，绝不会记录用户任何个人信息，使用过程中所产生的全部数据均保存在用户浏览器Cookie中，用户可随时通过清除浏览器Cookie缓存的方式删除所有数据。
      </p>
      <p>
        2、重要数据：UniToken 令牌是用户唯一的身份证明，记录了每个用户的剩余额度，及AI描述词相关的论文生成风格，
        <span style={{ color: '#a81313', fontWeight: 600 }}>用户必须单独记录保存</span>
        ，如若丢失或遗忘，本平台将不承担任何责任，也不存在找回功能。
      </p>
      <p>
        3、存档数据：存档数据包含论文目录、正文、参考文献，均采用加密形式存储，归档数据只可用于切换设备时在新设备上导入使用。因存档数据包含全部论文数据，请用户必须妥善保管。
      </p> */}
    </Modal>
  )
}

export default memo(withRouter(PlatformModal))
