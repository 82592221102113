import { memo, useState, useEffect } from 'react'
import { Modal } from 'antd'
import equity1 from '@/assets/images/equity1.jpeg'
import equity2 from '@/assets/images/equity2.jpeg'
import equity3 from '@/assets/images/equity3.jpeg'
import './MoreModal.less'
const MoreModal = (props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} moreModal-modal-container`}
      open={isModalOpen}
      onOk={handleOk}
      // onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <div className='img-box'>
        <i className='icon iconfont icon-chacha' onClick={handleCancel} />
        {[equity1, equity2, equity3].map((item: any, index: number) => {
          return <img key={index} src={item} alt='' />
        })}
      </div>
    </Modal>
  )
}

export default memo(MoreModal)
