export const gateway = '/api/'
const regist = gateway + 'unipaper/regist'
const userinfo = gateway + 'unipaper/userinfo'
const expend = gateway + 'unipaper/expend'
const wechat_login = gateway + 'wechat_login'
const pay = gateway + 'unipaper/pay'
const reference = gateway + 'unipaper/reference'
const pay_result = gateway + 'unipaper/pay_result'
const cnki = gateway + 'unipaper/cnki'
const reduce_aigc = gateway + 'unipaper/reduce_aigc'
const doc_docx = gateway + 'unipaper/doc_docx'
const html_docx = gateway + 'unipaper/html_docx'
const ppt = gateway + '/unipaper/ppt'
const docx_md = gateway + 'unipaper/docx_md'
const ppt_stream = gateway + 'unipaper/ppt_stream'
const ppt_raw = gateway + 'unipaper/ppt_raw'
const pay_coupon = gateway + 'unipaper/pay_coupon'
const expand_coupon = gateway + 'unipaper/expand_coupon'
const pdf_report = gateway + 'unipaper/pdf_report'
const payment_statements = gateway + '/payment_statements'
export const apiGroup = {
  pay_result,
  regist,
  userinfo,
  expend,
  wechat_login,
  pay,
  reference,
  cnki,
  reduce_aigc,
  doc_docx,
  html_docx,
  ppt,
  docx_md,
  ppt_stream,
  ppt_raw,
  pay_coupon,
  expand_coupon,
  pdf_report,
  payment_statements
}
const parseFile = gateway + 'presentation/outline/import'
const topic = gateway + 'presentation/generate/outline/ai/chat'
const historyId = gateway + 'presentation/history' // 创建PPT⽣成任务
const getTheme = gateway + 'presentation/theme' // get获取主题 post修改主题
const outline = gateway + 'presentation/outline' // 生成大纲
const diagram = gateway + 'presentation/diagram' // get 获取图示资源   post 修改图示资源
const presentation = gateway + 'presentation' // ⽣成 PPT
const checkResult = gateway + 'presentation/history' // 查询⽣成结果
export const pptApi = {
  parseFile,
  topic,
  historyId,
  outline,
  getTheme,
  diagram,
  presentation,
  checkResult
}
