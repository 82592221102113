import 'core-js/stable'
import 'regenerator-runtime/runtime'
import * as ReactDOM from 'react-dom'
import App from './App'
import '@/assets/styles/index.less'
// import { initTheme } from '@/assets/theme/theme'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import zhCN from 'antd/locale/zh_CN'
// initTheme('darkTheme')
dayjs.locale('zh-cn')

ReactDOM.render(
  // <React.StrictMode>
  <ConfigProvider
    locale={zhCN}
    theme={{
      token: {
        // Seed Token，影响范围大
        colorPrimary: '#000000'
        // 派生变量，影响范围小
        // colorBgContainer: '#f6ffed'
      }
    }}
  >
    <App />
  </ConfigProvider>,
  // </React.StrictMode>,
  document.getElementById('root') as HTMLElement
)
