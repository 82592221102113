import { memo } from 'react'
import { Upload, Button, message } from 'antd'
import CommonFunc from '@/utils/common-func'
const FileLoader = (props: any) => {
  const handleFileChange = (info: any) => {
    info.fileList = []
    // 获取当前操作的文件对象
    const file = info.file
    if (file) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        // 文件内容读取完成后的操作
        const result = e.target.result
        if (!result) {
          message.warning('导入文件有误')
          return
        }
        const content = CommonFunc.decrypt(result)
        try {
          const data = JSON.parse(content)
          console.log(data)
          if (!data.aiPaperData && !data.lowChongData && !data.lowAIGCData && !data.creatPPTData && !data.openReportData) {
            message.warning('导入文件有误')
            return
          }
          if (props.callback) {
            props.callback(data)
            message.success(`${info.file.name} 文件读取成功`)
          }
        } catch {
          message.warning('导入文件有误')
        }
      }
      reader.onerror = () => {
        // 文件读取失败的处理
        message.error('文件读取失败')
      }
      reader.readAsText(file) // 以文本形式读取文件
    }
  }

  return (
    <Upload
      beforeUpload={() => false} // 禁止自动上传
      accept='.paper'
      onChange={handleFileChange} // 文件状态改变时的回调
      showUploadList={false} // 不展示文件列表
      className={`${props.className} fileLoader-btn`}
    >
      <Button type='primary' className={props.desc ? 'ant-btn2' : ''}>
        <span className='sp1'>{props.text || '选择文件'}</span>
        {props.desc ? <span className='sp2'>{props.desc || '选取 [导出存档] 生成的文件'}</span> : null}
      </Button>
    </Upload>
  )
}

export default memo(FileLoader)
