import { memo, useState, useEffect, useContext, useRef, Fragment } from 'react'
import { Button, Popover } from 'antd'
import { ThemeContext, CHAT_DEFAULT, defaultData, defaultCreatPPTData, defaultOpenReportData } from '@/store/store'
import {
  DATA,
  LUNWEN_CLASSIFY,
  data2,
  LIST,
  BaseProgramData1,
  BaseProgramData2,
  duplicateRemoval,
  ADVANTAGE,
  REPORT_LEFT,
  REPORT_RIGHT
} from './const'
import LoginModal from '@/components/LoginModal/LoginModal'
import BaseProgram from '@/components/BaseProgram/BaseProgram'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import ClassifyBtn from './ClassifyBtn'
import Footer from './Footer'
import ExplorationGroup from '@/assets/images/ExplorationGroup.png'
import officialWechat from '@/assets/images/officialWechat.png'
import ppt from '@/assets/images/ppt.png'
import JoinGroup from '@/components/JoinGroup/JoinGroup'
import BuyModal from '@/components/BuyModal/BuyModal'
import UserModal from '@/components/UserModal/UserModal'
import PlatformModal from '@/components/PlatformModal/PlatformModal'
import MoreModal from '@/components/MoreModal/MoreModal'
import LunwenModal from '@/components/LunwenModal/LunwenModal'
import UpToBottomScroll from '@/components/UpToBottomScroll/UpToBottomScroll'
import { setSessionStorage, setCreatPPTSessionStorage, setInputReportSessionStorage } from '@/server/commonFetch'
import { DESC } from '../ThesisTopic/const'
import dailiPng from '@/assets/images/dailiPng.jpg'
import kefuCode from '@/assets/images/kefuCode.png'
// declare const window: any
import './index.less'
const unityUrl = process.env.VITE_NAME
const Index = (props: any) => {
  const { dispatch, clientInfo, id } = useContext<any>(ThemeContext)
  const [loginVisible, setLoginVisible] = useState<any>(false)
  const [type, setType] = useState<any>(-1)
  const [moreVisible, setMoreVisible] = useState(-1)
  const [active, setActive] = useState<number>(1)
  const [advantageindex, setAdvantageindex] = useState<number>(0)
  const [selectBtn, setSelectBtn] = useState<number>(0)
  const [slide, setSlide] = useState<number>(1)
  const [joinGroupVisible, setJoinGroupVisible] = useState(false)
  const [lookMore, setLookMore] = useState(false)
  const [fadeClass, setFadeClass] = useState('')
  const height = clientInfo.height < 500 ? 500 + 'px' : clientInfo.height + 'px'
  const timer = useRef<any>(null)
  // const width = clientInfo.width < 1440 ? 1440 + 'px' : clientInfo.width + 'px'
  // const height = ((1440 / 875) * clientInfo.width) / 100 + 'rem'
  useEffect(() => {
    if (id) {
      scrollToSection(id)
      dispatch({ type: CHAT_DEFAULT, payload: { id: null } })
    }
    const aiPaperData = localStorage.getItem('ai-paper-data')
    if (aiPaperData) {
      const data = JSON.parse(aiPaperData)
      dispatch({ type: CHAT_DEFAULT, payload: { aiPaperData: [...data] } })
    }
  }, [])
  useEffect(() => {
    const interval = setInterval(() => {
      // 应用渐变效果
      setFadeClass('fade-out')
      setSlide(slide => (slide === 3 ? 1 : slide + 1))
      setTimeout(() => {
        setActive(prevActive => (prevActive === 3 ? 1 : prevActive + 1))
        setFadeClass('fade-in')
      }, 250) // 确保这个时长与 CSS 中的 transition 时长一致
    }, 10000) // 每10秒更新一次内容

    return () => clearInterval(interval)
  }, [])
  useEffect(() => {
    loop()
    return () => clearInterval(timer.current)
  }, [])
  const loop = () => {
    if (timer.current) {
      clearInterval(timer.current)
    }
    timer.current = setInterval(() => {
      setSelectBtn(prevSelectBtn => {
        return prevSelectBtn + 1 > 4 ? 0 : prevSelectBtn + 1
      })
    }, 10000) // 每10秒更新一次内容
  }
  const createText = (idx: any) => {
    if (!localStorage.getItem('uniToken')) {
      setLoginVisible(true)
      setType(idx)
      return
    }
    if (['ppt'].includes(idx)) {
      // setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
      setCreatPPTSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultCreatPPTData } })
      props.history.push(unityUrl + '/creatPPT')
      return
    }
    if (['课程论文', '毕业论文 (本科)', '毕业论文 (专科)'].includes(idx)) {
      const obj: any = { 课程论文: 0, '毕业论文 (本科)': 1, '毕业论文 (专科)': 2 }
      setSessionStorage({
        dispatch,
        CHAT_DEFAULT,
        payload: { ...defaultData, thesisType: obj[idx], lunwenzishu: DESC[obj[idx]][DESC[obj[idx]].length - 1].label }
      })
      props.history.push(unityUrl + '/thesisTopic')
      return
    }
    // 降重或降AIGC
    if (idx === 5 || idx === 6) {
      setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
      props.history.push(unityUrl + '/lowInfo/' + idx)
      return
    }
    if (idx == 'openreport') {
      setInputReportSessionStorage({
        dispatch,
        CHAT_DEFAULT,
        payload: { ...defaultOpenReportData }
      })
      props.history.push(unityUrl + '/openReportTopic')
      return
    }
    // 0:去润色 1:去扩写 2:去缩写 3:去批阅
    props.history.push(unityUrl + '/paragraphModify?type=' + idx)
  }
  const selectNameClick = (val: any, index: number) => {
    const obj: any = {
      0: 'zhengwen',
      1: 'ppt',
      2: 'duanluo',
      3: 'jianchong',
      4: 'openreport',
      5: 'advantage',
      6: 'welcome'
    }
    scrollToSection(obj[index])
  }
  const scrollToSection = (id: string) => {
    const section: any = document.getElementById(id)
    section.scrollIntoView({ behavior: 'smooth' })
  }
  const tabAdvantage = (index: number) => {
    setAdvantageindex(index)
  }
  const content = (
    <div>
      {[
        { icon: 'icon-icon-user', label: '用户信息' },
        { icon: 'icon-chongzhi', label: '充值中心' },
        { icon: 'icon-icon-wenhao', label: '使用说明' },
        { icon: 'icon-lunwen', label: '论文管理' }
      ].map((item: any, index: number) => {
        return (
          <div
            key={index}
            tabIndex={0}
            className={`more-item ${index == 3 ? 'more-item-last' : ''}`}
            onFocus={() => {
              console.log(`聚焦了索引为：${index} 的项目`)
              if (!localStorage.getItem('uniToken')) {
                setLoginVisible(true)
                setType('7' + index)
              } else {
                setMoreVisible(index)
              }
            }}
          >
            <i className={`icon iconfont ${item.icon}`} />
            <span>{item.label}</span>
          </div>
        )
      })}
    </div>
  )
  return (
    <main className='index-container'>
      <ul className='ul-title'>
        <li className='li-first' onClick={() => scrollToSection('top')}>
          <i className='icon iconfont icon-xuewei icon-title-paper' />
          AI UniPaper
        </li>
        {['生成正文', '答辩PPT', '段落修改', '降重/降AIGC', '开题报告', '安全性/优势', '活动/招募'].map(
          (item: any, index: number) => {
            return (
              <li key={index} onClick={() => selectNameClick(item, index)}>
                {index == 6 ? <SvgIcon className='hot-tip' href={`icon-HOT`} /> : null}
                {index == 4 ? <SvgIcon className='hot-tip openreport' href={`icon-xianmian`} /> : null}
                {item}
              </li>
            )
          }
        )}
        <li className='li-login'>
          <i className='icon iconfont icon-dunpai icon-login-paper' />
          免登录
        </li>
        <li className='li-more'>
          <Popover
            content={content}
            title=''
            trigger='focus'
            placement='topRight'
            arrow={{ pointAtCenter: true }}
            overlayClassName='li-more-drop'
          >
            <i className='icon iconfont icon-a-44tubiao-124 icon-more-paper' tabIndex={0} />
          </Popover>
        </li>
      </ul>
      <div className='index-wrapper index-first-wrapper' id='top' style={{ height }}>
        <div className='unipager-introduction'>
          <div className='left left-1'>
            <div className={`slide slide-${slide}`}></div>
            {DATA.map((item: any, index: number) => {
              return (
                <div key={index} className={`left-title left-title-${item.level}`}>
                  <div className='catalog-title'>{item.title}</div>
                  {item.desc && <div className={`catalog-desc`}>{item.desc}</div>}
                </div>
              )
            })}
          </div>
          <div className='right right-1'>
            <div className='content'>
              <ClassifyBtn data={data2} />
              {!DATA[active].en && <div className='content-mb'></div>}
              {DATA[active].content.map((item: any, index: number) => {
                return (
                  <div key={index} className={`right-content ${fadeClass}`}>
                    {item}
                  </div>
                )
              })}
              {(DATA[active].en || []).map((item: any, index: number) => {
                return (
                  <div key={index} className={`right-content right-en ${fadeClass}`}>
                    {item}
                    <div className='mb'></div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className='btn-wrapper'>
            <Button
              className='ant-btn2 ant-btn-size2'
              onClick={() => {
                setJoinGroupVisible(true)
                // const section: any = document.getElementById('welcome')
                // section.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              <span className='sp1'>Free Try</span>
              <span className='sp2'>5000字 免费试用</span>
            </Button>
            <Button type='primary' className='ant-btn-size2' onClick={() => scrollToSection('zhengwen')}>
              生成正文
            </Button>
          </div>
          <Footer
            className='pagerFooter'
            version2='4.0'
            title='AI UniPaper 专注大学论文'
            desc='AI辅助大学生撰写高质量论文'
          ></Footer>
        </div>
      </div>
      <div className={`index-wrapper lunwen-classsify-wrapper`} id='zhengwen' style={{ height }}>
        <div className='unipager-classsify'>
          {LUNWEN_CLASSIFY.map((item: any, index: number) => {
            return (
              <div className='item-classsify' key={index}>
                <div className='lunwen-title'>
                  <span>{item.title}</span>
                  <span>{item.enTitle}</span>
                </div>
                <div className='lunwen-desc'>
                  <span>{item.desc1}</span>
                  <span>{item.desc2}</span>
                  <span>{item.desc3}</span>
                  <span>{item.desc4}</span>
                  {item.desc5 && <span>{item.desc5}</span>}
                </div>
                <div className='lunwen-footer'>
                  <Button type='primary' className='ant-btn-size2' onClick={() => createText(item.title)}>
                    开始创作
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={`index-wrapper ppt-wrapper`} id='ppt' style={{ height }}>
        <img src={ppt} alt='' />
        <div className='footer'>
          <div className='ppt-left'>
            <span>海量PPT模版</span>
            <span>多种大纲模式 3步完成答辩PPT</span>
          </div>
          <div className='ppt-center'>
            {[
              { icon: 'icon-Ai', name: 'AI 自动生成' },
              { icon: 'icon-WORD', name: '由论文原稿生成' },
              { icon: 'icon-xuewei-copy', name: '由UniPaper论文生成' }
            ].map((item: any, index: number) => {
              return (
                <div className='box' key={index}>
                  {index == 1 ? (
                    <SvgIcon className='icon-baogao' href={`${item.icon}`} color='#0ff' />
                  ) : (
                    <i className={`icon iconfont ${item.icon} icon_${index}`} />
                  )}

                  <span>{item.name}</span>
                </div>
              )
            })}
          </div>
          <Button className='ant-btn-size2 antd-btn-normal' onClick={() => createText('ppt')}>
            开始制作PPT
          </Button>
        </div>
      </div>
      <div className={`index-wrapper content-wrapper`} id='duanluo' style={{ height }}>
        <div className='unipager-runse'>
          <ClassifyBtn
            actived={selectBtn}
            data={LIST[selectBtn].data}
            type={2}
            classifyBtnName={`classifyBtnName`}
            onClick={(idx: number) => {
              setSelectBtn(idx)
              loop()
            }}
          />
          <div className='runse-content paragrapgh-content'>
            <div className='left'>
              {LIST.map((item: any, index: number) => {
                return (
                  <div key={index} className={`desc ${index == selectBtn ? 'desc-acitve' : ''}`}>
                    {item.left.map((itm: any, idx: number) => {
                      return <p key={idx}>{itm}</p>
                    })}
                    {index === 4 ? <BaseProgram data={BaseProgramData1} style={{ marginTop: '.14rem' }} /> : null}
                  </div>
                )
              })}
            </div>
            <div className={`right ${LIST[selectBtn].btn === '开始批阅' ? 'right-piyue' : ''}`}>
              {LIST.map((item: any, index: number) => {
                return (
                  <div key={index} className={`desc ${index == selectBtn ? 'desc-acitve' : ''} ${index === 3 ? 'desc-2' : ''}`}>
                    {item.right.map((itm: any, idx: number) => {
                      return <p key={idx}>{itm}</p>
                    })}
                    {index === 3 ? <span className='mb'></span> : null}
                    {index === 4 ? <BaseProgram data={BaseProgramData2} style={{ marginTop: '.14rem' }} /> : null}
                  </div>
                )
              })}
            </div>
            {/* <div className='left'>
              {LIST[selectBtn].left.map((itm: any, idx: number) => {
                return <p key={idx}>{itm}</p>
              })}
              {selectBtn === 4 ? <BaseProgram data={BaseProgramData1} style={{ marginTop: '.14rem' }} /> : null}
            </div>
            <div className={`right ${LIST[selectBtn].btn === '开始批阅' ? 'right-piyue' : ''}`}>
              {LIST[selectBtn].right.map((itm: any, idx: number) => {
                return <p key={idx}>{itm}</p>
              })}
              {selectBtn === 3 ? <span className='mb'></span> : null}
              {selectBtn === 4 ? <BaseProgram data={BaseProgramData2} style={{ marginTop: '.14rem' }} /> : null}
            </div> */}
          </div>
          <Footer
            className='pagerFooter pos-footer'
            version1='4.0'
            title={LIST[selectBtn].title}
            desc={LIST[selectBtn].desc}
            type='dark'
          ></Footer>
          <Button className='pos-btn ant-btn-size2 antd-btn-normal' onClick={() => createText(selectBtn)}>
            {LIST[selectBtn].btn}
          </Button>
        </div>
      </div>
      <div className={`index-wrapper lunwen-classsify-wrapper`} id='jianchong' style={{ height }}>
        <div className='unipager-classsify'>
          {duplicateRemoval.map((item: any, index: number) => {
            return (
              <div className={`item-classsify item-tenology-wrapper${index}`} key={index}>
                <div className='lunwen-title'>
                  <span>{item.title}</span>
                  <span>{item.enTitle}</span>
                </div>
                <div className='lunwen-desc'>
                  <span>{item.desc1}</span>
                  <span className='tenology-platform'>
                    {item.desc2.map((itm: any, idx: number) => {
                      return (
                        <label key={idx}>
                          <SvgIcon className='label-icon' href={`${itm.icon}`} />
                          {itm.label}
                        </label>
                      )
                    })}
                  </span>
                  <span>{item.desc3}</span>
                  <span>{item.desc4}</span>
                  <span>{item.desc5}</span>
                </div>
                <div className='lunwen-footer'>
                  <Button type='primary' className='ant-btn-size2' onClick={() => createText(+`${index + 5}`)}>
                    {item.btn}
                  </Button>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className={`index-wrapper openreport-wrapper`} id='openreport' style={{ height }}>
        <div className='unipager-openreport'>
          <div className='openreport-left'>
            {REPORT_LEFT.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <h4>{item.h4}</h4>
                  {item.content.map((itm: any, idx: number) => {
                    return (
                      <div key={idx} className={`div div${index}${idx}`}>
                        <p>{itm.title}</p>
                        <span>{itm.desc}</span>
                      </div>
                    )
                  })}
                </Fragment>
              )
            })}
          </div>
          <div className='openreport-right'>
            {REPORT_RIGHT.map((item: any, index: number) => {
              return (
                <Fragment key={index}>
                  <h4>{item.h4}</h4>
                  {item.content.map((itm: any, idx: number) => {
                    return <p key={idx}>{itm}</p>
                  })}
                </Fragment>
              )
            })}
          </div>
        </div>
        <div className='openreport-footer'>
          <Footer
            className='pagerFooter pos-footer pos-footer-1'
            version1='4.0'
            version11='领取体验字数 免费生成'
            title='AI UniPaper 开题报告'
            desc='对论文选题进行全面阐述，包含研究背景、国内外研究现状、研究方法、创新点、预期结果等'
            type='dark'
          ></Footer>
          <Button className='pos-btn ant-btn-size2 antd-btn-normal' onClick={() => createText('openreport')}>
            生成开题报告
          </Button>
        </div>
      </div>
      <div className={`index-wrapper advantage-wrapper advantage-wrapper-${advantageindex}`} id='advantage' style={{ height }}>
        <div className={`advantage-png advantage-${advantageindex}`}></div>
        <ul className='unipager-advantage'>
          {ADVANTAGE.map((item: any, index: number) => {
            return (
              <li className={`${advantageindex === index ? 'active' : ''}`} key={index} onClick={() => tabAdvantage(index)}>
                <span>{item.chName}</span>
                <span>{item.enName}</span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className={`index-wrapper index-wrapper-tiyan`} id='welcome' style={{ height }}>
        <div className='unipager-tiyan'>
          <div className='left'>
            <span>欢迎扫码 免费领取5000体验字数</span>
            <span>全部功能通用：包含 生成正文、段落修改、全文降重、全文降AIGC、开题报告</span>
          </div>
          <div className='right'>
            <img src={ExplorationGroup} />
            <span>加入官方微信社群</span>
            <span className=''>加入学生党互动 参与更多活动</span>
          </div>
        </div>
        <div className='daili'>
          <img src={dailiPng} />
          <Button type='primary' className='ant-btn-size3' onClick={() => setLookMore(true)}>
            点击查看更多权益
          </Button>
          <UpToBottomScroll></UpToBottomScroll>
        </div>
      </div>
      <div className={`index-wrapper`}>
        <div className='unipager-ais'>
          <div className='left'>
            {[
              { img: kefuCode, title: '官方企微客服' },
              { img: officialWechat, title: '官方微信公众号' }
            ].map((item: any, index: number) => {
              return (
                <div className='img-box' key={index}>
                  <img src={item.img} />
                  <span>{item.title}</span>
                </div>
              )
            })}
          </div>
          <div className='right'>
            <span>AI UniPaper</span>
            <span>www.aiunipaper.com</span>
            <span>© 2024 Shanghai Jianlian Technology Co., Ltd.</span>
          </div>
        </div>
      </div>
      <div className={`index-wrapper index-wrapper-footer`}>
        <div className='unipager-footer'>
          <span className='sp-left' onClick={() => window.open('https://beian.miit.gov.cn')}>
            沪ICP备2024004977号-8
          </span>
          <span className='sp-right'>
            ©2024 上海鉴联科技有限公司 <label onClick={() => window.open('http://www.easylink.vip')}>关于我们</label>{' '}
            公司地址：上海市嘉定区科福路368号
          </span>
        </div>
      </div>
      <LoginModal
        isModalOpen={loginVisible}
        type={type}
        callback={(val: string) => {
          setLoginVisible(false)
          setMoreVisible(+val.slice(-1))
        }}
        onCancel={() => setLoginVisible(false)}
      />
      {moreVisible == 1 && <BuyModal visible={true} onCancel={() => setMoreVisible(-1)} />}
      {moreVisible == 0 && (
        <UserModal
          visible={true}
          onCancel={() => {
            setMoreVisible(-1)
          }}
        />
      )}
      {moreVisible == 2 && <PlatformModal visible={true} onCancel={() => setMoreVisible(-1)} />}
      {moreVisible == 3 && (
        <LunwenModal visible={true} onCancel={() => setMoreVisible(-1)} scrollFunc={(id: any) => scrollToSection(id)} />
      )}
      <JoinGroup visible={joinGroupVisible} onCancel={() => setJoinGroupVisible(false)} />
      {lookMore && <MoreModal visible={lookMore} onCancel={() => setLookMore(false)} />}
    </main>
  )
}

export default memo(Index)
