/**
 *   create by zhanghang
 */
import { lazy } from 'react'
import withLazyLoad from '@/components/withLazyLoad/withLazyLoad'
import { InterRouteConfig } from './router.d'
const ThesisTopic = withLazyLoad(lazy(() => import('@/pages/ThesisTopic').then((module: any) => ({ default: module.default }))))
const Major = withLazyLoad(lazy(() => import('@/pages/Major').then((module: any) => ({ default: module.default }))))
const References = withLazyLoad(lazy(() => import('@/pages/References').then((module: any) => ({ default: module.default }))))
const MainBody = withLazyLoad(lazy(() => import('@/pages/MainBody').then((module: any) => ({ default: module.default }))))
const LowAIGC = withLazyLoad(lazy(() => import('@/pages/LowAIGC').then((module: any) => ({ default: module.default }))))
const ResulePPT = withLazyLoad(
  lazy(() => import('@/pages/ResulePPT/ResulePPT').then((module: any) => ({ default: module.default })))
)
const CreatPPT = withLazyLoad(
  lazy(() => import('@/pages/CreatPPT/CreatPPT').then((module: any) => ({ default: module.default })))
)
const ParagraphModify = withLazyLoad(
  lazy(() => import('@/pages/ParagraphModify').then((module: any) => ({ default: module.default })))
)
const OpenReportTopic = withLazyLoad(
  lazy(() => import('@/pages/OpenReportTopic').then((module: any) => ({ default: module.default })))
)
const OpenReportPlans = withLazyLoad(
  lazy(() => import('@/pages/OpenReportPlans').then((module: any) => ({ default: module.default })))
)
const OpenReportReferences = withLazyLoad(
  lazy(() => import('@/pages/OpenReportReferences').then((module: any) => ({ default: module.default })))
)
const OpenReportbody = withLazyLoad(
  lazy(() => import('@/pages/OpenReportbody').then((module: any) => ({ default: module.default })))
)
const unityUrl = process.env.VITE_NAME
const asyncRouter: InterRouteConfig[] = [
  {
    path: unityUrl + '/thesisTopic',
    name: 'ThesisTopic',
    exact: true,
    children: [],
    components: ThesisTopic,
    icon: '',
    show: true,
    class: 'cach-container cach-container-thesisTopic',
    code: 'UNI_PAGER_ThesisTopic',
    isUseCache: false
  },
  {
    path: unityUrl + '/major',
    name: 'Major',
    exact: true,
    children: [],
    components: Major,
    icon: '',
    show: true,
    class: 'cach-container cach-container-major',
    code: 'UNI_PAGER_Major',
    isUseCache: false
  },
  {
    path: unityUrl + '/references',
    name: 'References',
    exact: true,
    children: [],
    components: References,
    icon: '',
    show: true,
    class: 'cach-container cach-container-references',
    code: 'UNI_PAGER_References',
    isUseCache: false
  },
  {
    path: unityUrl + '/mainbody',
    name: 'MainBody',
    exact: true,
    children: [],
    components: MainBody,
    icon: '',
    show: true,
    class: 'cach-container cach-container-mainBody',
    code: 'UNI_PAGER_MainBody',
    isUseCache: false
  },
  {
    path: unityUrl + '/lowInfo/:id',
    name: 'LowAIGC',
    exact: true,
    children: [],
    components: LowAIGC,
    icon: '',
    show: true,
    class: 'cach-container cach-container-LowAIGC',
    code: 'UNI_PAGER_LowAIGC',
    isUseCache: false
  },
  {
    path: unityUrl + '/creatPPT',
    name: 'CreatPPT',
    exact: true,
    children: [],
    components: CreatPPT,
    icon: '',
    show: true,
    class: 'cach-container cach-container-CreatPPT',
    code: 'UNI_PAGER_CreatPPT',
    isUseCache: false
  },
  {
    path: unityUrl + '/resulePPT',
    name: 'ResulePPT',
    exact: true,
    children: [],
    components: ResulePPT,
    icon: '',
    show: true,
    class: 'cach-container cach-container-ResulePPT',
    code: 'ResulePPT',
    isUseCache: false
  },
  {
    path: unityUrl + '/paragraphModify',
    name: 'ParagraphModify',
    exact: true,
    children: [],
    components: ParagraphModify,
    icon: '',
    show: true,
    class: 'cach-container cach-container-paragraphModify',
    code: 'UNI_PAGER_ParagraphModify',
    isUseCache: false
  },
  {
    path: unityUrl + '/openReportTopic',
    name: 'OpenReportTopic',
    exact: true,
    children: [],
    components: OpenReportTopic,
    icon: '',
    show: true,
    class: 'cach-container cach-container-OpenReportTopic',
    code: 'OpenReportTopic',
    isUseCache: false
  },
  {
    path: unityUrl + '/openReportPlans',
    name: 'OpenReportPlans',
    exact: true,
    children: [],
    components: OpenReportPlans,
    icon: '',
    show: true,
    class: 'cach-container cach-container-OpenReportPlans',
    code: 'OpenReportPlans',
    isUseCache: false
  },
  {
    path: unityUrl + '/openReportReferences',
    name: 'OpenReportReferences',
    exact: true,
    children: [],
    components: OpenReportReferences,
    icon: '',
    show: true,
    class: 'cach-container cach-container-OpenReportReferences',
    code: 'OpenReportReferences',
    isUseCache: false
  },
  {
    path: unityUrl + '/openReportbody',
    name: 'OpenReportbody',
    exact: true,
    children: [],
    components: OpenReportbody,
    icon: '',
    show: true,
    class: 'cach-container cach-container-OpenReportbody',
    code: 'OpenReportbody',
    isUseCache: false
  }
]
export default asyncRouter
