import { memo } from 'react'
import SmallBtn from '@/components/SmallBtn/SmallBtn'
import './index.less'
declare interface IProps {
  data: any[]
  style?: React.CSSProperties
  type?: Number
  classifyBtnName?: String
  onClick?: (index: number, e: any) => void
  actived?: Number | undefined
}
const ClassifyBtn = (props: IProps) => {
  return (
    <div className={`classifyBtn-container ${props.classifyBtnName || ''}`}>
      {props.data.map((item: any, index: number) => {
        return (
          <SmallBtn
            className={`${item.className || ''}`}
            notllowedAnimation={item.notllowedAnimation}
            style={{ ...item.style }}
            type={props.type}
            key={index}
            actived={index === props.actived}
            onClick={e => {
              if (props.onClick) {
                props.onClick(index, e) // 调用父组件传递的onClick方法
              }
            }}
          >
            {item.name}
          </SmallBtn>
        )
      })}
    </div>
  )
}

export default memo(ClassifyBtn)
