export const secretIv = '1234567890123456' // 密钥偏移量
export const secretKey = 'IWPAX9g6oEe1rLas' // 密钥
export const runse = '润色'
export const kuoxie = '扩写'
export const suoxie = '缩写'
export const piyue = '批阅'
export const chtoen = '中/英'
export const chongxie = '重写'
export const aigc = '降AIGC'
export const model = '4o' // '4.0' '4o'
export const DEAL_ICON = ['icon-arrowLeft', 'icon-arrowRight', 'icon-jia', 'icon-refresh-1-copy', 'icon-edit', 'icon-lajitong']
export const DEAL_ICON_2 = ['icon-arrowLeft', 'icon-arrowRight', 'icon-jia', 'icon-edit', 'icon-lajitong']
export const hide = 30
export const author = '学校 - 专业 - 姓名'
export const link = 'www.aiunipaper.com'
export const digtToChinese: any = {
  1: '一',
  2: '二',
  3: '三',
  4: '四',
  5: '五',
  6: '六',
  7: '七',
  8: '八',
  9: '九',
  10: '十',
  11: '十一',
  12: '十二',
  13: '十三',
  14: '十四',
  15: '十五',
  16: '十六',
  17: '十七',
  18: '十八',
  19: '十九',
  20: '二十',
  21: '二十一',
  22: '二十二',
  23: '二十三',
  24: '二十四',
  25: '二十五',
  26: '二十六',
  27: '二十七',
  28: '二十八',
  29: '二十九',
  30: '三十',
  31: '三十一',
  32: '三十二',
  33: '三十三',
  34: '三十四',
  35: '三十五',
  36: '三十六',
  37: '三十七',
  38: '三十八',
  39: '三十九',
  40: '四十',
  41: '四十一',
  42: '四十二',
  43: '四十三',
  44: '四十四',
  45: '四十五',
  46: '四十六',
  47: '四十七',
  48: '四十八',
  49: '四十九',
  50: '五十',
  51: '五十一',
  52: '五十二',
  53: '五十三',
  54: '五十四',
  55: '五十五',
  56: '五十六',
  57: '五十七',
  58: '五十八',
  59: '五十九',
  60: '六十'
}

export const convertTitleToNumber: any = {
  第一章: '1',
  第二章: '2',
  第三章: '3',
  第四章: '4',
  第五章: '5',
  第六章: '6',
  第七章: '7',
  第八章: '8',
  第九章: '9',
  第十章: '10',
  第十一章: '11',
  第十二章: '12',
  第十三章: '13',
  第十四章: '14',
  第十五章: '15',
  第十六章: '16',
  第十七章: '17',
  第十八章: '18',
  第十九章: '19',
  第二十章: '20',
  第二十一章: '21',
  第二十二章: '22',
  第二十三章: '23',
  第二十四章: '24',
  第二十五章: '25',
  第二十六章: '26',
  第二十七章: '27',
  第二十八章: '28',
  第二十九章: '29',
  第三十章: '30'
}

export const PPT_STEP_DATA = [
  { title: '① 生成大纲', en: 'Outline' },
  { title: '② 选择主题', en: 'Theme' },
  { title: '③ 生成PPT', en: 'Completion' }
]

export const OPEN_REPORT_STEP = [
  { title: '① 论文选题', en: 'Topics' },
  { title: '② 阶段计划', en: 'Plans' },
  { title: '③ 参考文献', en: 'References' },
  { title: '④ 论文正文', en: 'Body' }
]

export const tourData = [
  { title: '标记段落', description: '支持关联参考文献、插入表格、公式、代码' },
  { title: '生成正文', description: '支持逐段生成正文' },
  { title: '一键全文', description: '支持一键生成全文' }
]
