import { memo } from 'react'
import './SmallBtn.less'
declare interface SmallBtnProps {
  className?: string
  notllowedAnimation?: boolean
  style?: React.CSSProperties
  children: React.ReactNode
  type?: Number
  onClick?: (e: any) => void
  actived?: Boolean | undefined
}
const SmallBtn = (props: SmallBtnProps) => {
  return (
    <button
      className={`${props.className || ''} smallbtn-container ${props.notllowedAnimation ? 'cancleBtnAnimation' : ''} smallbtn_${
        props.type || 1
      } ${props.actived ? 'active-btn' : ''}`}
      style={{ ...props.style }}
      onClick={e => {
        if (props.onClick) {
          e.stopPropagation()
          props.onClick(e) // 调用父组件传递的onClick方法
        }
      }}
    >
      {props.children}
    </button>
  )
}

export default memo(SmallBtn)
