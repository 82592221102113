import { memo } from 'react'
import { Spin } from 'antd'
import './BasicSpin.less'
const BasicSpin = (props: any) => {
  return (
    <div className='basicspin-loading' data-theme={props.theme}>
      <div className='wrapper'>
        <Spin />
        {props.text && (
          <span
            className='text'
            onClick={() => {
              if (props.onStopClick) {
                props.onStopClick()
              }
            }}
          >
            {props.text}
          </span>
        )}
      </div>
    </div>
  )
}

export default memo(BasicSpin)
