import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import CommonFunc from '@/utils/common-func'

export const expendApi = async ({ dispatch, CHAT_DEFAULT, words, step, onSuccess, onError }: any) => {
  try {
    const res: any = await http('post', apiGroup.expend, {
      unitoken: localStorage.getItem('uniToken'),
      words,
      function: step
    })
    // console.log('expend', res)
    if (res.status && res.data) {
      getUserInfoApi({ dispatch, CHAT_DEFAULT, onSuccess, onError })
    } else {
      if (onError) {
        onError(res.msg)
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export const getUserInfoApi = async ({ dispatch, CHAT_DEFAULT, onSuccess, onError }: any) => {
  if (!localStorage.getItem('uniToken')) return
  try {
    const res: any = await http('post', apiGroup.userinfo, { unitoken: localStorage.getItem('uniToken') })
    // console.log('userinfo', res)
    const list = res.data.coupons.find((item: any) => item.type === 1)?.list || []
    const used: any = []
    const all: any = []
    list.forEach((item: any) => {
      all.push(item)
      if (item.use_status == 1) {
        used.push(item)
      }
    })
    // all.forEach((item: any) => {
    //   item.created_at = item.create_at
    // })
    used.forEach((item: any) => {
      // item.created_at = item.create_at
      item.function = 'PPT生成'
    })
    res.data.expand_records = [...res.data.expand_records, ...used].sort((a: any, b: any) => b.created_at - a.created_at)
    res.data.payment_records = [...res.data.payment_records, ...all].sort((a: any, b: any) => b.created_at - a.created_at)
    if (res.status) {
      dispatch({ type: CHAT_DEFAULT, payload: { userInfo: res.data } })
      localStorage.setItem('ai-pager-userInfo', JSON.stringify(res.data))
      if (onSuccess) {
        onSuccess(res.data)
      }
    } else {
      if (onError) {
        onError(res.msg)
      }
    }
  } catch (err) {
    console.log(err)
  }
}

export const setSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = sessionStorage.getItem('lunwenInfo')
  if (data) {
    const lunwenInfo = JSON.parse(data)
    sessionStorage.setItem('lunwenInfo', JSON.stringify({ ...lunwenInfo, ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  } else {
    sessionStorage.setItem('lunwenInfo', JSON.stringify({ ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  }
}
export const getSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('lunwenInfo')
  if (data) {
    const lunwenInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: lunwenInfo })
    return JSON.parse(data)
  }
}

export const setLocalStorage = ({ dispatch, CHAT_DEFAULT, aiPaperData, catalog }: any) => {
  aiPaperData[0].catalog = [...catalog]
  aiPaperData[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem('ai-paper-data', JSON.stringify(aiPaperData))
  dispatch({ type: CHAT_DEFAULT, payload: { aiPaperData: [...aiPaperData] } })
}
export const setLowInfoLocalStorage = ({ dispatch, CHAT_DEFAULT, lowKey, storeKey, list, paragraphNum }: any) => {
  const data: any = JSON.parse(localStorage.getItem(lowKey) || '[]')
  if (paragraphNum) {
    data[0].paragraphNum = paragraphNum
  }
  data[0].list = [...list]
  data[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem(lowKey, JSON.stringify(data))
  dispatch({ type: CHAT_DEFAULT, payload: { [storeKey]: [...data] } })
}

export const setCreatPPTSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  let creatPPTInfo = JSON.parse(sessionStorage.getItem('creatPPTInfo') || '{}')
  creatPPTInfo = { ...creatPPTInfo, ...payload }
  dispatch({ type: CHAT_DEFAULT, payload: { ...creatPPTInfo } })
  sessionStorage.setItem('creatPPTInfo', JSON.stringify({ ...creatPPTInfo }))
}

export const getCreatPPTSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('creatPPTInfo')
  if (data) {
    const creatPPTInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: creatPPTInfo })
    return JSON.parse(data)
  }
}

export const setCreatPPTLocalStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = JSON.parse(localStorage.getItem('creat-ppt-data') || '[]')
  data[0] = { ...data[0], ...payload, time: CommonFunc.timestampToTime(new Date().getTime(), true) }
  localStorage.setItem('creat-ppt-data', JSON.stringify([...data]))
  dispatch({ type: CHAT_DEFAULT, payload: { creatPPTData: [...data] } })
}
export const setInputReportSessionStorage = ({ dispatch, CHAT_DEFAULT, payload }: any) => {
  const data = sessionStorage.getItem('inputReportInfo')
  if (data) {
    const inputReportInfo = JSON.parse(data)
    sessionStorage.setItem('inputReportInfo', JSON.stringify({ ...inputReportInfo, ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  } else {
    sessionStorage.setItem('inputReportInfo', JSON.stringify({ ...payload }))
    dispatch({ type: CHAT_DEFAULT, payload })
  }
}
export const getInputReportSessionStorage = ({ dispatch, CHAT_DEFAULT }: any) => {
  const data = sessionStorage.getItem('inputReportInfo')
  if (data) {
    const inputReportInfo = JSON.parse(data)
    dispatch({ type: CHAT_DEFAULT, payload: inputReportInfo })
    return JSON.parse(data)
  }
}
export const setInputReportLocalStorage = ({ dispatch, CHAT_DEFAULT, openReportData, catalog }: any) => {
  openReportData[0].catalog = [...catalog]
  openReportData[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
  localStorage.setItem('open-report-data', JSON.stringify(openReportData))
  dispatch({ type: CHAT_DEFAULT, payload: { openReportData: [...openReportData] } })
}
