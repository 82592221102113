import { memo } from 'react'
import './index.less'
declare interface IProps {
  title?: string
  desc?: string
  className?: string
  version1?: string
  version2?: string
  type?: string
  version11?: string
}
const Footer = (props: IProps) => {
  return (
    <div className={`${props.className || ''} footer-container ${props.type ? `footer-container-${props.type}` : ''}`}>
      <div className='title'>
        <span>{props.title}</span>
        {props.version1 ? <span className='version1'>{props.version1}</span> : null}
        {props.version11 ? <span className='version1 version11'>{props.version11}</span> : null}
      </div>
      <div className='desc'>
        <span>{props.desc}</span>
        {props.version2 ? <span className='version2'>{props.version2}</span> : null}
      </div>
    </div>
  )
}

export default memo(Footer)
