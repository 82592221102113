import { memo, useState, useEffect, useContext, useRef } from 'react'
import { ThemeContext, CHAT_DEFAULT } from '@/store/store'
import { withRouter } from 'react-router-dom'
import { Modal, Button, QRCode, message } from 'antd'
import SvgIcon from '@/components/SvgIcon/SvgIcon'
import { http } from '@/server/http'
import { apiGroup } from '@/server/api-group'
import BasicSpin from '@/components/BasicSpin/BasicSpin'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { getUserInfoApi } from '@/server/commonFetch'
import './BuyModal.less'
const BuyModal = (props: any) => {
  const { dispatch, userInfo = {} } = useContext<any>(ThemeContext)
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  const [words, setWords] = useState(1)
  const [showPay, setShowPay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState(props.tab || 0)
  const [url, setUrl] = useState('')
  const userType = userInfo.invite_code ? 'inviteUser' : 'generalUser'
  const tip: any = {
    0: {
      generalUser: { title: '9折优惠 限时福利', sendTitle: '充3万字 送PPT券', price: 27, id: 101, sendId: 1 },
      inviteUser: { title: '校园代理邀请 限时8折优惠', sendTitle: '充3万字 送PPT券', price: 24, id: 102, sendId: 1 }
    },
    1: {
      generalUser: { title: '9折优惠 限时福利', sendTitle: null, price: 27, id: 101, sendId: null },
      inviteUser: { title: '校园代理邀请 限时8折优惠', sendTitle: null, price: 24, id: 102, sendId: null }
    }
  }
  const timer = useRef<any>(null)
  const controller = new AbortController()
  useEffect(() => {
    setLoading(true)
    getUserInfoApi({
      dispatch,
      CHAT_DEFAULT,
      onSuccess: () => {
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
    return () => {
      clearTimeout(timer.current)
      // controller && typeof controller.abort === 'function' && controller.abort()
    }
  }, [])
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (controller && typeof controller.abort === 'function') {
      controller.abort()
    }
    setShowPay(false)
    clearTimeout(timer.current)
    if (props.onCancel) props.onCancel()
  }
  const createCode = async (count = words) => {
    setLoading(true)
    try {
      const res: any = await http('post', tab == 0 ? apiGroup.pay : apiGroup.pay_coupon, {
        // id: tab == 0 ? 100 : 101,
        id: tip[tab][userType].id,
        count,
        unitoken: localStorage.getItem('uniToken')
      })
      if (res.status) {
        setShowPay(true)
        setUrl(res.data.code_url)
        setLoading(false)
        payment_statements(res.data.out_trade_no)
      } else {
        message.error(res.msg)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      if (typeof err === 'string') {
        message.error(err)
      }
      setLoading(false)
    }
  }
  const payment_statements = async (out_trade_no: any) => {
    try {
      const res: any = await http('get', apiGroup.payment_statements, { out_trade_no }, controller.signal)
      // console.log(res)
      if (res.status && res.data && res.data.trade_state == 'SUCCESS') {
        message.success('支付成功')
        setShowPay(false)
        clearTimeout(timer.current)
        getUserInfoApi({ dispatch, CHAT_DEFAULT })
        if (words >= 3 && tab == 0) payAgain()
      } else {
        clearTimeout(timer.current)
        timer.current = setTimeout(() => {
          payment_statements(out_trade_no)
        }, 2000)
      }
    } catch {
      clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        payment_statements(out_trade_no)
      }, 2000)
    }
  }
  const payAgain = async () => {
    try {
      const res: any = await http('post', apiGroup.pay_coupon, {
        id: tip[tab][userType].sendId,
        count: Math.floor(words / 3),
        unitoken: localStorage.getItem('uniToken')
      })
      if (res.status) {
        message.success('赠送产品已到账')
        getUserInfoApi({ dispatch, CHAT_DEFAULT })
      } else {
        message.error(res.msg)
      }
    } catch (err) {
      console.log(err)
      if (typeof err === 'string') {
        message.error(err)
      }
    }
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} buyModal-modal-container easy-modal`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      title=''
      footer={null}
      centered
    >
      {!showPay && loading && <BasicSpin theme='dark' />}
      <h4 className='title'>
        {['购买字数', '购PPT券'].map((item: any, index: number) => {
          return (
            <span
              key={index}
              className={`tab-span ${tab == index ? 'active' : ''}`}
              onClick={() => {
                setTab(index)
                setWords(1)
                setShowPay(false)
              }}
            >
              {item}
            </span>
          )
        })}
      </h4>
      <CopyToClipboard text={localStorage.getItem('uniToken')} onCopy={() => message.success('UniToken 已复制')}>
        <div className='token'>
          UniToken：
          <span>{localStorage.getItem('uniToken')}</span>
          <span className='copy'>（点击复制）</span>
        </div>
      </CopyToClipboard>
      {tab == 0 ? (
        <div className='intro'>
          <div className='left'>
            <span>单价：</span>
            <span>
              {tip[tab][userType].price}元/<label>万字</label>
              <label className='orgin-price'> 30元/万字 </label>
              <i className='tip tip2'>{tip[tab][userType].title}</i>
            </span>
          </div>
          <div className='right'>
            <span>
              剩余字数：
              <label>{userInfo.sum_words - userInfo.expend_words >= 0 ? userInfo.sum_words - userInfo.expend_words : 0}</label>
            </span>
          </div>
        </div>
      ) : (
        <div className='intro'>
          <div className='left'>
            <span>单价：</span>
            <span>
              {tip[tab][userType].price}元/<label>张</label>
              <label className='orgin-price'> 30元/张 </label>
              <i className='tip tip2'>{tip[tab][userType].title}</i>
            </span>
          </div>
          <div className='right'>
            <span>
              剩余张数：
              <label>{userInfo.coupons.find((item: any) => item.type == 1)?.ppt_coupons_count || 0}</label>
            </span>
          </div>
        </div>
      )}
      <div className='buyInfo'>
        <div className='left'>
          <i
            className={`icon iconfont icon-heifangkuaijian doicon ${loading ? 'disabled' : ''}`}
            onClick={() => {
              if (loading || words === 1) return
              clearTimeout(timer.current)
              setWords(words > 0 ? words - 1 : 0)
              setShowPay(false)
            }}
          />
          <div className='text'>
            <span>{words}</span>
            <span>{tab == 0 ? '万字' : '张'}</span>
          </div>
          <i
            className={`icon iconfont icon-heifangkuaijia doicon ${loading ? 'disabled' : ''}`}
            onClick={() => {
              if (loading) return
              clearTimeout(timer.current)
              setWords(words + 1)
              setShowPay(false)
            }}
          />
          {tab == 0 ? (
            <div className='zengsong'>
              {/* <span>赠送</span>
              <span>{Math.floor(words / 2) * 5000}字</span> */}
              {Math.floor(words / 3) >= 1 ? (
                <>
                  <span>赠送</span>
                  <label>PPT券{Math.floor(words / 3)}张</label>
                </>
              ) : (
                <span className='sendTitle'>{tip[tab][userType].sendTitle}</span>
              )}
            </div>
          ) : null}
        </div>
        <div className='right'>
          <Button type='primary' onClick={() => createCode()}>
            下单
          </Button>
        </div>
      </div>
      {tab == 0 && <div className='func_desc'>全功能通用：包含 生成正文、段落修改、降重、降AIGC、开题报告</div>}
      {showPay ? (
        <div className='codeInfo'>
          <span className='heji'>合计：{words * tip[tab][userType].price}元</span>
          <span className='desc'>请使用微信支付</span>
          <div className='wrapper'>
            {loading && <BasicSpin theme='dark' />}
            <QRCode value={url || '-'} />
          </div>
          <SvgIcon className='icon-wechat' href={`icon-wechat`} />
        </div>
      ) : null}
    </Modal>
  )
}

export default memo(withRouter(BuyModal))
