// /**
//  *   create by zhanghang
//  */
import { Suspense } from 'react'
// import BaseSpin from 'components/BaseSpin/BaseSpin'

import './withLazyLoad.less'

const withLazyLoad = (WrappedComponent: any) => (props: any) =>
  (
    <Suspense
      fallback={
        <div className='baseSpin-wrap' style={{ width: '100%' }}>
          {/* <BaseSpin /> */}
        </div>
      }
    >
      <WrappedComponent {...props} />
    </Suspense>
  )

export default withLazyLoad
