import { memo, useState, useEffect, useContext } from 'react'
import { ThemeContext, CHAT_DEFAULT, defaultData, defaultCreatPPTData, defaultOpenReportData } from '@/store/store'
import { withRouter } from 'react-router-dom'
import { Modal, Button, Popconfirm } from 'antd'
import { setSessionStorage } from '@/server/commonFetch'
import CommonFunc from '@/utils/common-func'
import './LunwenModal.less'
const unityUrl = process.env.VITE_NAME
const LunwenModal = (props: any) => {
  const {
    dispatch,
    aiPaperData = [],
    lowChongData = [],
    lowAIGCData = [],
    creatPPTData = [],
    openReportData = []
  } = useContext<any>(ThemeContext)
  const [NavData, setNavData] = useState([
    { title: '正文', data: aiPaperData },
    { title: 'PPT', data: creatPPTData },
    { title: '降重', data: lowChongData },
    { title: '降AIGC', data: lowAIGCData },
    { title: '开题报告', data: openReportData }
  ])
  const obj: any = { 0: '课程论文', 1: '毕业论文（本科）', 2: '毕业论文（专科）', 5: '降重', 6: '降AIGC' }
  const [navIndex, setNavIndex] = useState(0)
  const [isModalOpen, setIsModalOpen] = useState(props.visible)
  useEffect(() => {
    setIsModalOpen(props.visible)
  }, [props.visible])
  const handleOk = () => {
    if (props.onOk) props.onOk()
  }
  const handleCancel = () => {
    if (props.onCancel) props.onCancel()
  }
  const tabClick = (e: any, index: number) => {
    if (navIndex == 2 || navIndex == 3) {
      setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { lowStep: 3 } })
    }
    const obj: any = {
      0: { storeKey: 'aiPaperData', data: aiPaperData, key: 'ai-paper-data', url: unityUrl + '/mainbody' },
      1: { storeKey: 'creatPPTData', data: creatPPTData, key: 'creat-ppt-data', url: unityUrl + '/resulePPT' },
      2: { storeKey: 'lowChongData', data: lowChongData, key: 'low-chong-data', url: unityUrl + '/lowInfo/5' },
      3: { storeKey: 'lowAIGCData', data: lowAIGCData, key: 'low-aigc-data', url: unityUrl + '/lowInfo/6' },
      4: { storeKey: 'openReportData', data: openReportData, key: 'open-report-data', url: unityUrl + '/openReportbody' }
    }
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;[obj[navIndex].data[0], obj[navIndex].data[index]] = [obj[navIndex].data[index], obj[navIndex].data[0]]
    obj[navIndex].data[0].time = CommonFunc.timestampToTime(new Date().getTime(), true)
    localStorage.setItem(obj[navIndex].key, JSON.stringify(obj[navIndex].data))
    dispatch({ type: CHAT_DEFAULT, payload: { [obj[navIndex].storeKey]: [...obj[navIndex].data] } })
    handleCancel()
    props.history.push(obj[navIndex].url)
  }
  const delItem = (index: number) => {
    const obj: any = {
      0: { storeKey: 'aiPaperData', data: aiPaperData, key: 'ai-paper-data', url: unityUrl + '/' },
      1: { storeKey: 'creatPPTData', data: creatPPTData, key: 'creat-ppt-data', url: unityUrl + '/creatPPT' },
      2: { storeKey: 'lowChongData', data: lowChongData, key: 'low-chong-data', url: unityUrl + '/lowInfo/5' },
      3: { storeKey: 'lowAIGCData', data: lowAIGCData, key: 'low-aigc-data', url: unityUrl + '/lowInfo/6' },
      4: { storeKey: 'openReportData', data: openReportData, key: 'open-report-data', url: unityUrl + '/openReportbody' }
    }
    obj[navIndex].data.splice(index, 1)
    localStorage.setItem(obj[navIndex].key, JSON.stringify(obj[navIndex].data))
    dispatch({ type: CHAT_DEFAULT, payload: { [obj[navIndex].storeKey]: [...obj[navIndex].data] } })
    if (!localStorage.getItem(obj[navIndex].key) || localStorage.getItem(obj[navIndex].key) === '[]') {
      props.history.push(obj[navIndex].url)
      if (obj[navIndex].key === 'low-chong-data' || obj[navIndex].key === 'low-aigc-data') {
        setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { lowStep: 1 } })
      }
    }
    NavData[navIndex].data = obj[navIndex].data
    setNavData([...NavData])
  }
  return (
    <Modal
      wrapClassName={`${props.className || ''} lunwenModal-modal-container easy-modal`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      title=''
      footer={null}
      centered
    >
      <h4 className='title'>论文管理</h4>
      <ul>
        {NavData.map((item: any, index: number) => {
          return (
            <li key={index} className={`${navIndex === index ? 'active' : ''}`} onClick={() => setNavIndex(index)}>
              {item.title}
            </li>
          )
        })}
      </ul>
      <div className='content'>
        {NavData[navIndex].data.length ? (
          NavData[navIndex].data.map((item: any, index: number) => {
            return (
              <div className={`item ${location.pathname !== unityUrl + '/mainbody' ? 'no-item' : ''}`} key={index}>
                <span className='sp1'>{navIndex == 1 ? item.outLine.title : item.title}</span>
                <span className='sp4'>
                  {navIndex == 1 ? '答辩PPT' : navIndex == 4 ? '开题报告' : obj[item.thesisType]}
                  <label>{item.thesisType > 3 ? `${item.paragraphNum}个段落` : ''}</label>
                </span>
                <span className='sp2'>上次修改时间：{item.time}</span>
                {(index === 0 && location.pathname === unityUrl + '/mainbody' && navIndex === 0) ||
                (index === 0 && location.pathname === unityUrl + '/resulePPT' && navIndex === 1) ||
                (index === 0 && location.pathname === unityUrl + '/lowInfo/5' && navIndex === 2) ||
                (index === 0 && location.pathname === unityUrl + '/lowInfo/6' && navIndex === 3) ||
                (index === 0 && location.pathname === unityUrl + '/openReportbody' && navIndex === 4) ? (
                  <span className='sp3'>正在编辑</span>
                ) : (
                  <Button type='primary' onClick={e => tabClick(e, index)}>
                    切换
                  </Button>
                )}
                <Popconfirm key={index} title='确认删除？' icon={null} onConfirm={() => delItem(index)}>
                  <i className='icon iconfont icon-lajitong' />
                </Popconfirm>
              </div>
            )
          })
        ) : (
          <div className='no-data'>暂无数据</div>
        )}
      </div>
      <Button
        type='primary'
        onClick={() => {
          if (navIndex === 0) {
            setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
            dispatch({ type: CHAT_DEFAULT, payload: { id: 'zhengwen' } })
            if (location.pathname !== unityUrl + '/') {
              props.history.push(unityUrl + '/')
            } else {
              props.scrollFunc('zhengwen')
            }
          }
          if (navIndex === 1) {
            // console.log('降重')
            sessionStorage.removeItem('creatPPTInfo')
            setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultCreatPPTData } })
            props.history.push(unityUrl + '/creatPPT')
          }
          if (navIndex === 2) {
            console.log('降重')
            sessionStorage.removeItem('lowInfo')
            setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
            props.history.push(unityUrl + '/lowInfo/5')
          }
          if (navIndex === 3) {
            console.log('降AIGC')
            setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultData } })
            sessionStorage.removeItem('lowInfo')
            props.history.push(unityUrl + '/lowInfo/6')
          }
          if (navIndex === 4) {
            console.log('开题报告')
            setSessionStorage({ dispatch, CHAT_DEFAULT, payload: { ...defaultOpenReportData } })
            sessionStorage.removeItem('inputReportInfo')
            props.history.push(unityUrl + '/openReportTopic')
          }
          handleCancel()
        }}
      >
        新建
      </Button>
    </Modal>
  )
}

export default memo(withRouter(LunwenModal))
